<script setup lang="ts">
import type { PropType } from 'vue';
import type { PartialDeep } from 'type-fest';
import { card as cardConfig } from '#ui/ui.config';
import colors from '#tailwind-config/theme/colors';

defineOptions({
  inheritAttrs: false,
});

type PartialUiConfig = PartialDeep<typeof cardConfig>;

const props = defineProps({
  color: {
    type: String as PropType<keyof typeof colors>,
    default: 'primary',
  },
  class: {
    type: [String, Object, Array] as PropType<any>,
    default: undefined,
  },
  ui: {
    type: Object as PropType<PartialUiConfig>,
    default: () => ({}),
  },
  collapsable: {
    type: Boolean,
    default: false,
  },
  collapsedIntitalState: {
    type: Boolean,
    default: true,
  },
});

const backgroundColor: ComputedRef<string> = computed(() => {
  switch (props.color) {
    case 'primary':
      return 'bg-white dark:bg-gray-900';
    case 'red':
      return 'bg-red-300 text-white';
    case 'blue':
      return 'bg-blue-500 text-white';
    default: {
      // TODO: Abstract color didn't work, so hardcoded by props
      // const baseColor = colors[props.color] ? colors[props.color]['500'] : props.color;
      // return `background-color:${baseColor}`;
      return `bg-${props.color}-50`;
    }
  }
});

const emit = defineEmits(['expandableClicked']);

const config = computed(() => ({
  wrapper: '',
  background: backgroundColor.value,
  ring: 'ring-0 border-black border-l border-t border-r border-b-4',
  rounded: 'rounded-2xl',
}));

const { ui, attrs } = useUI(
  'card',
  toRef(props, 'ui'),
  config,
  toRef(props, 'class'),
  true
);

const expandableClicked = () => {
  isExpanded.value = !isExpanded.value;
  emit('expandableClicked', isExpanded.value);
};

const isExpanded = ref(props.collapsedIntitalState);
</script>

<template>
  <UCard :ui="ui" :class="ui.wrapper" v-bind="attrs">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <div
        v-if="collapsable && name === 'header'"
        :key="name"
        class="group flex cursor-pointer justify-between"
        @click="expandableClicked"
      >
        <slot :name="name" v-bind="slotData" :is-expanded="isExpanded" />
        <UButton
          icon="i-heroicons-chevron-up-20-solid"
          :ui="{
            base: 'transform transition-transform duration-200 !text-black self-start ',
            rounded: 'rounded-full',
            variant: {
              ghost: 'group-hover:bg-primary-50',
            },
          }"
          :class="{ '-rotate-180': isExpanded }"
          size="sm"
          variant="ghost"
          square
        />
      </div>

      <Transition v-if="collapsable && name === 'default'" :key="name">
        <div v-show="isExpanded">
          <slot :name="name" v-bind="slotData" :is-expanded="isExpanded" />
        </div>
      </Transition>

      <slot
        v-if="!collapsable || !['header', 'default'].includes(name as string)"
        :name="name"
        v-bind="slotData"
        :is-expanded="isExpanded"
      />
    </template>
  </UCard>
</template>
